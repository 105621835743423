import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState();

  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          const formData = {
            phoneNumber: phoneNumber,
            password: values.password,
          };
          console.log(formData);
          await login({
            phoneNumber: phoneNumber,
            password: values.password,
          });
          onSuccess();
        }}
        // schema={schema}
      >
        {({ register, formState }) => (
          <>
            <PhoneInputField
              label="Phone Number"
              error={formState.errors['phoneNumber']}
              registration={register('phoneNumber')}
              onChange={(value) => setPhoneNumber(value)}
            />
            <InputField
              type="password"
              label="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full">
                Sign in
              </Button>
            </div>
          </>
        )}
      </Form>
      <button className="w-full mt-4" onClick={() => navigate('/auth/register')}>
        Create new WLU account
      </button>
      <button
        className="w-full text-sm text-[#142852] underline"
        type="button"
        onClick={() => navigate('/auth/forgot-password')}
      >
        Forgot Password?
      </button>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
