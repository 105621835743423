import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Logo } from 'components/Layout';
import { useLocation, useParams } from 'react-router-dom';

// Load Stripe
const stripePromise = loadStripe('YOUR_PUBLIC_STRIPE_KEY');

const WLUCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('34000'); // Default UGX 34,000
  const [email, setEmail] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const location = useLocation();

  // Extract 'secret' from the query string
  const queryParams = new URLSearchParams(location.search);
  const secret = queryParams.get('secret');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setPaymentStatus('Stripe is not loaded.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Confirm Card Payment
      const { paymentIntent, error } = await stripe.confirmCardPayment(secret || clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { email },
        },
      });

      if (error) {
        setPaymentStatus(`Payment failed: ${error.message}`);
      } else if (paymentIntent) {
        setPaymentStatus('Payment successful! Thank you.');
      }
    } catch (err) {
      setPaymentStatus(`Server error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
        <div className="flex flex-row items-center justify-center mb-6">
          <Logo />
        </div>
        <h2 className="text-xl font-semibold text-gray-800 text-center">WLU Membership Payment</h2>
        <p className="text-gray-600 text-center mb-4">
          Pay UGX {parseInt(amount).toLocaleString()} to join With Love Uganda.
        </p>
        <form onSubmit={handleSubmit}>
          {!secret && (
            <>
              <label className="block mb-2 text-sm font-medium text-gray-600">Payment Secret</label>
              <input
                type="text"
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ED1D24]"
                placeholder="Enter your payment secret"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                required
              />
            </>
          )}
          <label className="block mb-2 text-sm font-medium text-gray-600">Email Address</label>
          <input
            type="email"
            className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ED1D24]"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="block mb-2 text-sm font-medium text-gray-600">Card Details</label>
          <div className="p-2 border rounded-lg mb-4">
            <CardElement />
          </div>
          <button
            type="submit"
            className={`w-full bg-[#ED1D24] text-white py-2 px-4 rounded-lg transition ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#b1262a]'
            }`}
            disabled={loading || !stripe || !elements}
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </button>
        </form>
        {paymentStatus && (
          <p
            className={`mt-4 text-sm text-center ${
              paymentStatus.includes('successful') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {paymentStatus}
          </p>
        )}
      </div>
    </div>
  );
};

export const WLUCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <WLUCheckoutForm />
    </Elements>
  );
};

export default WLUCheckout;
