import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import BgImg from 'assets/bg.png';
import BannerImg from 'assets/banner.png';
import { Logo } from 'components/Layout';
import { Info } from 'react-feather';
import { Link } from 'react-router-dom';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div
        className="min-h-screen bg-white"
        style={{
          backgroundImage: `url(${BgImg})`,
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="mx-8 md:mx-48 my-4 md:my-24 flex flex-col items-center justify-center">
          <Logo />
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="">
              <div className="bg-white rounded-3xl shadow-md border border-gray-200">
                <div className="bg-gray-100 border-b border-gray-200  p-4 text-center flex flex-row items-center space-x-2 justify-center rounded-t-3xl">
                  <div className="text-xl font-semibold">{title}</div>
                  <Info />
                </div>
                <div className="p-6">{children}</div>
              </div>
              <div className="my-2 mx-4 text-xs">
                By continuing, you agree to 
                <Link to="/terms-and-conditions" className="text-red-500 underline">
                  WLU Terms and Conditions
                </Link>{' '}
                 or other agreement for WLU services, and the 
                <Link to="/privacy-policy" className="text-red-500 underline">
                  Privacy Notice
                </Link>
                . This site uses essential cookies. See our 
                <Link to="/cookie-notice" className="text-red-500 underline">
                  Cookie Notice
                </Link>
                 for more information.
              </div>
            </div>
            <div className="hidden md:block">
              <img src={BannerImg} alt="banner" />
            </div>
          </div>
          <div className="text-sm mt-12">
            © 2024 With Love Uganda, Ltd. or its affiliates. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
