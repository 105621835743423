import clsx from 'clsx';
import { FieldWrapper } from './FieldWrapper';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

export const PhoneInputField = (props) => {
  const { className, caption, label, registration, error, onChange } = props;

  const handleInputChange = (value, country) => {
    if (onChange) {
      onChange(value, country);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div
        className={clsx(
          'flex flex-col space-y-2 appearance-none block w-full bg-white rounded-md',
          className
        )}
      >
        <PhoneInput
          country={'ug'} // Default country
          onChange={handleInputChange}
          inputProps={{
            name: registration?.name || 'phone',
            required: true,
          }}
          inputStyle={{
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #D1D5DB', // gray-300
            borderRadius: 0,
            fontSize: '1.125rem', // sm:text-lg (18px)
            height: '8vh',
          }}
        />
        <div className="my-1 text-xs flex justify-end">{caption}</div>
      </div>
    </FieldWrapper>
  );
};
