import { Link, useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import storage from 'utils/storage';
import { useEffect } from 'react';

export const AccountCreatedSuccess = () => {
  const navigate = useNavigate();
  const clientSecret = storage.getClientSecret();

  useEffect(() => {
    window.scrollTo({
      top: 0, // Scroll to the top
      behavior: 'smooth', // Smooth scrolling
    });
  }, []);

  return (
    <Layout title="New Account Created">
      <div className="flex flex-col space-y-2">
        <div className="">You've successfully created a new account with With Love Uganda. </div>
        <div className="text-sm">
          Please note that you're required to make a payment of{' '}
          <span className="text-red-500 font-bold">UGX 34,000</span> to complete your payment.
        </div>
        <div className="text-sm">
          Click the link below to complete payment or simply visit copy your Payment Secret to{' '}
          <Link to="/pay/checkout" target="_blank">
            WLU Payments
          </Link>{' '}
          to complete your payment.
        </div>
        <Link
          to={`/pay/checkout?secret=${clientSecret}`}
          target="_blank"
          className="bg-yellow-100 p-4 my-12 text-center text-yellow-500 shadow"
        >
          <code>{clientSecret}</code>
        </Link>
      </div>
    </Layout>
  );
};
