import { Spinner } from 'components/Elements';
import { loginWithPhoneAndPassword, getUser, registerWithPhoneAndPassword } from 'features/auth';
import storage from 'utils/storage';
import { initReactQueryAuth } from 'react-query-auth';

async function handleUserResponse(data) {
  const { token, user } = data;
  storage.setToken(token);
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    const { user } = await getUser();

    return user;
  }
  return null;
}

async function loginFn(data) {
  console.log(data);
  const response = await loginWithPhoneAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  return await registerWithPhoneAndPassword(data);
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
