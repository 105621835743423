import logo from 'assets/logo.svg';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <Link to="/">
      <img className="h-14 md:h-20" src={logo} alt="Medisawa" />
    </Link>
  );
};
