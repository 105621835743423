import React from 'react';
import FooterBg from 'assets/footer-bg.png';
// import JumiaLogo from 'assets/jumia.png';
// import NaivasLogo from 'assets/na.png';
// import SafaricomLogo from 'assets/safaricom.png';
// import StartimesLogo from 'assets/startimes.png';
// import AirtelLogo from 'assets/airtel.png';
// import UbaLogo from 'assets/uba.png';
// import VisaLogo from 'assets/visa.png';
import { Facebook, Instagram, Linkedin, Twitter } from 'react-feather';

export const Footer = () => {
  return (
    <footer className=" text-gray-800">
      {/* Partners Section */}
      {/* <div className="py-8 text-center">
        <h2 className="text-3xl font-bold">Backed by world-class funders and partners.</h2>
        <div className="flex flex-wrap justify-center items-center space-x-6 mt-4">
          <img src={JumiaLogo} alt="Jumia" className="h-10" />
          <img src={NaivasLogo} alt="Naivas" className="h-10" />
          <img src={SafaricomLogo} alt="Safaricom" className="h-10" />
          <img src={StartimesLogo} alt="Startimes" className="h-10" />
          <img src={AirtelLogo} alt="Airtel" className="h-10" />
          <img src={UbaLogo} alt="UBA" className="h-10" />
          <img src={VisaLogo} alt="Visa" className="h-10" />
        </div>
      </div> */}

      {/* Newsletter Section */}
      <div
        className={`text-white py-12 bg-cover bg-center bg-no-repeat relative mx-4 md:mx-24 rounded-3xl mb-12`}
        style={{ backgroundImage: `url(${FooterBg})` }}
      >
        {/* Content */}
        <div className="relative container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-12">
          <div className="mb-8 lg:mb-0">
            <h3 className="text-4xl font-semibold">
              Subscribe to
              <br />
              <span className="text-teal-400">newsletter</span>
            </h3>
            <p className="text-gray-400 mt-2">
              We promise not to send you any spam and never publish your email.
            </p>
          </div>
          <div className="flex items-center bg-gray-800 rounded-full overflow-hidden">
            <input
              type="email"
              placeholder="Your email address"
              className="flex-1 px-4 py-3 bg-transparent text-gray-300 focus:outline-none"
            />
            <button className="px-6 py-3 bg-teal-500 text-white font-medium rounded-full hover:bg-teal-600 transition duration-200">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="bg-gray-50 py-6">
        <div className="container mx-auto grid grid-cols-2 sm:grid-cols-4 gap-4 text-sm text-center lg:text-left">
          <div>
            <h4 className="font-semibold mb-2">About Us</h4>
            <ul>
              <li>Our Payments Gateway</li>
              <li>Powering Businesses</li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Payments</h4>
            <ul>
              <li>For Merchants</li>
              <li>For Banks</li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Our People</h4>
            <ul>
              <li>Careers</li>
              <li>Knowledge Hub</li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Support</h4>
            <ul>
              <li>Press and Media</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Social Media Icons and Legal Links */}
      <div className="bg-gray-100 py-4">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-12 text-sm">
          <div className="flex space-x-4 mb-4 lg:mb-0">
            <a href="#" className="hover:text-teal-500">
              Privacy Policy
            </a>
            <a href="#" className="hover:text-teal-500">
              Cookie Policy
            </a>
            <a href="#" className="hover:text-teal-500">
              Data Request Form
            </a>
            <a href="#" className="hover:text-teal-500">
              Whistleblowing
            </a>
          </div>
          <div className="flex space-x-6">
            <a href="#" aria-label="Twitter">
              <Twitter className="h-5" />
            </a>
            <a href="#" aria-label="Facebook">
              <Facebook className="h-5" />
            </a>
            <a href="#" aria-label="LinkedIn">
              <Linkedin className="h-5" />
            </a>
            <a href="#" aria-label="Instagram">
              <Instagram className="h-5" />
            </a>
          </div>
        </div>
        <div className="text-center mt-4 text-gray-400">Medisawa 2024. All Rights Reserved.</div>
      </div>
    </footer>
  );
};
