import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';

import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import HealthCareImg from 'assets/providing-healthcare.png';
import Img16 from 'assets/image-16.png';
import Img17 from 'assets/image-17.png';
import Img18 from 'assets/image-18.png';
import BusinessImg from 'assets/business.png';
import PatientsImg from 'assets/patients.png';
import CountriesImg from 'assets/countries.png';
import DigitalWalletImg from 'assets/digital-wallet.png';
import ApproachImg from 'assets/approach.png';
import BannerImg from 'assets/banner.png';

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      {/* Section: Banner */}
      <section className="relative bg-gradient-to-r from-[#073C46] to-[#5AA7B6] text-white py-16">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 items-center px-6 md:px-16 gap-12">
          {/* Content Area */}
          <div className="space-y-6">
            <h1 className="text-3xl md:text-5xl font-extrabold leading-snug">
              Transforming <span className="text-[#FFC857]">Healthcare</span> in Africa <br />
              <span className="text-[#F9F9FB]">Through Technology</span>.
            </h1>
            <p className="text-lg md:text-xl leading-relaxed">
              Join us on a journey to redefine healthcare accessibility and quality. Our platform
              leverages cutting-edge technology to make a lasting impact in communities across
              Africa.
            </p>
            <button className="bg-[#FFC857] hover:bg-[#E6AE41] text-[#073C46] font-bold py-3 px-8 rounded-full shadow-lg transition-all duration-300">
              Download Our App
            </button>
          </div>

          {/* Image Area */}
          <div className="relative">
            <div className="rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition-transform duration-500">
              <img src={BannerImg} alt="Healthcare Banner" className="object-cover w-full h-auto" />
            </div>
            {/* Floating Badge */}
            <div className="absolute bottom-4 right-4 bg-[#073C46] text-[#FFC857] px-4 py-2 rounded-lg shadow-lg text-sm font-medium">
              Empowering Communities
            </div>
          </div>
        </div>

        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-32 h-32 bg-[#FFC857] rounded-full blur-xl opacity-30 -z-10"></div>
        <div className="absolute bottom-0 right-0 w-48 h-48 bg-[#073C46] rounded-full blur-2xl opacity-30 -z-10"></div>
      </section>

      {/* Section: Our digital solutions */}
      <div className="my-12">
        <div className="text-3xl font-bold text-[#003C47] text-center mb-2">
          Our Digital Solutions.
        </div>
        <div className="text-center mb-8">
          Through our innovative mobile app, we help providers deliver affordable, high-quality care
          to patients who need it.
        </div>
      </div>

      {/* Section: Our Approach */}
      <section className="bg-[#F9F9FB] py-20">
        <div className="max-w-6xl mx-auto px-6 md:px-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
            {/* Left Image */}
            <div className="col-span-1 relative">
              <div className="rounded-lg overflow-hidden shadow-lg">
                <img
                  src={ApproachImg}
                  alt="Our Approach"
                  className="object-cover w-full h-full transform hover:scale-110 transition-transform duration-500"
                />
              </div>
              <div className="absolute top-4 left-4 bg-[#FFC857] text-[#4C4F9D] px-4 py-2 rounded-lg shadow-md text-sm font-semibold">
                Transforming Healthcare
              </div>
            </div>

            {/* Content Area */}
            <div className="col-span-2 space-y-8">
              <div className="bg-[#03030c] p-8 rounded-lg shadow-lg">
                <h2 className="text-4xl text-white font-bold mb-4">Our Approach</h2>
                <p className="text-[#F9F9FB] text-lg leading-relaxed">
                  We focus on making healthcare accessible by reimbursing pharmacies and clinics
                  that provide quality care at subsidized costs.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <div className="w-16 h-16 flex items-center justify-center bg-[#03030c] text-[#F9F9FB] rounded-full shadow-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20 12H4"
                    />
                  </svg>
                </div>
                <p className="text-[#2D2D2D] text-lg">
                  Our app simplifies business management for pharmacies and clinics, empowering them
                  to thrive while focusing on patients.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <div className="w-16 h-16 flex items-center justify-center bg-[#03030c] text-[#F9F9FB] rounded-full shadow-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 10h11M9 21v-6m-6 0h18m-6 0v6m6-18v4m-6 2h6M3 10v6m18-6v6"
                    />
                  </svg>
                </div>
                <p className="text-[#2D2D2D] text-lg">
                  Using data insights, we continuously learn and improve care, ensuring that the
                  communities we serve benefit the most.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Our Impact */}
      <div className="px-4 md:px-24 py-12">
        <div className="text-3xl font-bold text-[#003C47] text-center mb-2">Our Impact.</div>
        <div className="text-center mb-12 text-lg mx-auto md:w-[50%]">
          Medisawa has created one of the largest digital networks of private pharmacies and clinics
          in Africa.
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-12">
          <div className="flex flex-col items-center md:border-r md:border-dashed">
            <div className="h-16 my-2">
              <img src={BusinessImg} alt="businesses" className="h-16" />
            </div>
            <div className="">130+</div>
            <div className="text-center text-sm">businesses use our technology</div>
          </div>

          <div className="flex flex-col items-center md:border-r md:border-dashed">
            <div className="h-16 my-2">
              <img src={PatientsImg} alt="patients" className="h-16" />
            </div>
            <div className="">650,000+</div>
            <div className="text-center text-sm">
              patients access affordable healthcare medicines
            </div>
          </div>

          <div className="flex flex-col items-center md:border-r md:border-dashed">
            <div className="h-16 my-2">
              <img src={DigitalWalletImg} alt="digital wallet" className="h-16" />
            </div>
            <div className="">50,000+</div>
            <div className="text-center text-sm">digital wallets linked to our technology</div>
          </div>

          <div className="flex flex-col items-center">
            <div className="h-16 my-2">
              <img src={CountriesImg} alt="countries" className="h-16" />
            </div>
            <div className="">4</div>
            <div className="text-center text-sm">countries where we make an impact</div>
          </div>
        </div>
        <div className="text-center mb-12 text-lg mx-auto md:w-[50%]">
          Our programs have helped hundreds of thousands of people access care for malaria, family
          planning, and HIV prevention.
        </div>
      </div>

      {/* Section: What We Offer */}
      <section className="relative bg-gradient-to-br from-[#E0F7FA] via-[#B2EBF2] to-[#80DEEA] py-16 px-6 md:px-24">
        <div className="max-w-7xl mx-auto">
          {/* Section Title */}
          <h2 className="text-center text-4xl font-extrabold text-[#003C47] mb-12">
            What We Offer
          </h2>
          {/* Offering Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Card 1 */}
            <div className="bg-white rounded-lg shadow-xl p-6 hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300">
              <img
                src={Img16}
                alt="All-in-one App for Businesses"
                className="h-32 w-32 mx-auto mb-6"
              />
              <h3 className="text-center text-xl font-semibold text-[#00796B] mb-4">
                An All-in-one App for Businesses
              </h3>
              <p className="text-gray-600 text-center">
                Leave pen-and-paper behind! Manage sales, scan barcodes, monitor stock levels, and
                view inventory and earnings through an up-to-date dashboard.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white rounded-lg shadow-xl p-6 hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300">
              <img src={Img17} alt="Patient Support Programs" className="h-32 w-32 mx-auto mb-6" />
              <h3 className="text-center text-xl font-semibold text-[#00796B] mb-4">
                Patient Support Programs
              </h3>
              <p className="text-gray-600 text-center">
                We reward clinics and pharmacies for offering affordable, high-quality care and help
                them restock medications that meet exceptional standards.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white rounded-lg shadow-xl p-6 hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300">
              <img src={Img18} alt="Real-time Data" className="h-32 w-32 mx-auto mb-6" />
              <h3 className="text-center text-xl font-semibold text-[#00796B] mb-4">
                A Wealth of Real-time Data
              </h3>
              <p className="text-gray-600 text-center">
                Track health trends in malaria, family planning, and HIV with unprecedented,
                real-time data—handled with the utmost security and privacy.
              </p>
            </div>
          </div>
        </div>

        {/* Decorative Blobs */}
        <div className="absolute -top-10 -left-10 w-40 h-40 bg-[#80DEEA] rounded-full blur-2xl opacity-30 -z-10"></div>
        <div className="absolute -bottom-10 -right-10 w-60 h-60 bg-[#B2EBF2] rounded-full blur-3xl opacity-30 -z-10"></div>
      </section>

      {/* Section: Providing health care */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mx-4 md:mx-24 my-12">
        <div className="text-3xl text-black font-bold ml-4 md:ml-16">
          Providing healthcare businesses with a single,{' '}
          <span className="text-[#5AA7B6]">seamless platform</span>, enabling them to provide
          essential medicines at last-mile,{' '}
          <span className="text-[#5AA7B6]">while improving their businesses</span>.
        </div>
        <div className="">
          <img src={HealthCareImg} alt="healthcare image" />
        </div>
      </div>
    </WebLayout>
  );
};
