import React, { useState } from 'react';
import { Logo } from '..';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-md sticky top-0 w-full z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <Logo />

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          <a href="#what-we-do" className="text-gray-700 hover:text-black transition duration-200">
            What We Do
          </a>
          <a href="#careers" className="text-gray-700 hover:text-black transition duration-200">
            Careers
          </a>
          <a href="#team" className="text-gray-700 hover:text-black transition duration-200">
            Meet Our Team
          </a>
          <a href="#contact-us" className="text-gray-700 hover:text-black transition duration-200">
            Contact Us
          </a>
        </nav>

        {/* Mobile Menu */}
        <div className="md:hidden">
          <button
            className="text-gray-700 focus:outline-none"
            aria-label="Open menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation Links */}
      {isMenuOpen && (
        <nav className="md:hidden bg-white shadow-md">
          <div className="px-4 py-2 space-y-2">
            <a
              href="#what-we-do"
              className="block text-gray-700 hover:text-black transition duration-200"
              onClick={() => setIsMenuOpen(false)}
            >
              What We Do
            </a>
            <a
              href="#careers"
              className="block text-gray-700 hover:text-black transition duration-200"
              onClick={() => setIsMenuOpen(false)}
            >
              Careers
            </a>
            <a
              href="#newsroom"
              className="block text-gray-700 hover:text-black transition duration-200"
              onClick={() => setIsMenuOpen(false)}
            >
              Newsroom
            </a>
            <a
              href="#contact-us"
              className="block text-gray-700 hover:text-black transition duration-200"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact Us
            </a>
          </div>
        </nav>
      )}
    </header>
  );
};
