import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import storage from 'utils/storage';
import * as z from 'zod';

const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  middleName: z.string().optional(),
  email: z.string().email('Please enter a valid email'),
  password: z
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .regex(/[a-zA-Z]/, 'Password must contain at least one letter'),
  physical_address: z.string().min(5, 'Physical address is required'),
  terms_and_conditions: z
    .boolean()
    .refine((val) => val === true, 'You must agree to the terms and conditions'),
  privacy_policy: z.boolean().refine((val) => val === true, 'You must accept the privacy policy'),
  cookie_notice: z.boolean().refine((val) => val === true, 'You must accept the cookie notice'),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneError, setPhoneError] = useState();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          if (!phoneNumber || phoneNumber > 10) {
            setPhoneError('Phone number must be atleast 10 digits.');
          }
          const result = await register({ phoneNumber, ...values });
          storage.setClientSecret(result.clientSecret);
          navigate('/registration/success');
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            {/* First Name */}
            <InputField
              type="text"
              label="First Name"
              error={formState.errors['firstName']}
              registration={register('firstName')}
            />

            {/* Last Name */}
            <InputField
              type="text"
              label="Last Name"
              error={formState.errors['lastName']}
              registration={register('lastName')}
            />

            {/* Middle Name */}
            <InputField
              type="text"
              label="Middle Name (Optional)"
              error={formState.errors['middleName']}
              registration={register('middleName')}
            />

            {/* Phone Number */}
            <PhoneInputField
              label="Phone Number"
              error={phoneError}
              registration={register('phoneNumber')}
              onChange={(value) => setPhoneNumber(value)}
            />

            {/* Email */}
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />

            {/* Password */}
            <InputField
              type="password"
              label="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />

            {/* Physical Address */}
            <InputField
              type="text"
              label="Physical Address"
              error={formState.errors['physical_address']}
              registration={register('physical_address')}
            />

            {/* Terms and Conditions */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="terms_and_conditions"
                {...register('terms_and_conditions')}
                className="mr-2"
              />
              <label htmlFor="terms_and_conditions" className="text-sm">
                I agree to the{' '}
                <Link to="/terms-and-conditions" className="text-[#142852] underline">
                  Terms and Conditions
                </Link>
              </label>
            </div>

            {/* Privacy Policy */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="privacy_policy"
                {...register('privacy_policy')}
                className="mr-2"
              />
              <label htmlFor="privacy_policy" className="text-sm">
                I accept the{' '}
                <Link to="/privacy-policy" className="text-[#142852] underline">
                  Privacy Policy
                </Link>
              </label>
            </div>

            {/* Cookie Notice */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="cookie_notice"
                {...register('cookie_notice')}
                className="mr-2"
              />
              <label htmlFor="cookie_notice" className="text-sm">
                I agree to the{' '}
                <Link to="/cookie-notice" className="text-[#142852] underline">
                  Cookie Notice
                </Link>
              </label>
            </div>

            {/* Submit Button */}
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full">
                Sign Up
              </Button>
            </div>
          </>
        )}
      </Form>

      {/* Redirect to Login if already have an account */}
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          Already have an account?{' '}
          <Link to="../login" className="font-medium text-[#142852] underline hover:text-blue-500">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
